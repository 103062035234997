<template>
  <v-card>
    <v-card-text class="text-subtitle-1">
      <v-icon class="mr-2">mdi-gauge</v-icon>
      Votre taux de bonnes réponses
    </v-card-text>
    <div class="d-flex justify-center pa-4">
      <div class="d-flex text-center align-items-center">
        <v-overlay :value="loading" absolute opacity="0.1">
          <v-progress-circular
            indeterminate
            color="white"></v-progress-circular>
        </v-overlay>
        <VueSvgGauge
          v-if="!loading"
          :start-angle="-90"
          :end-angle="90"
          :value="cubeCount"
          :separator-step="0"
          :scale-interval="10"
          :gauge-color="[
            { offset: 0, color: '#ff6384' },
            { offset: 50, color: '#ff9f40' },
            { offset: 80, color: '#62bc8c' },
          ]">
          <div class="inner-text mt-15">
            <div class="text-h5">{{ cubeCount }}%</div>
          </div>
        </VueSvgGauge>
      </div>
    </div>
  </v-card>
</template>

<script>
import axios from 'axios';
import { mapStores } from 'pinia';
import { useStatsStore } from '@/store/stats';

export default {
  name: 'UserAvgChallengeScore.vue',
  data() {
    return {
      cubeData: null,
      dataKey: 'ChallengeTrials.avgScore',
      loading: true,
    };
  },
  watch: {
    'statsStore.currentStatsUserId': 'fetchData',
  },
  methods: {
    fetchData() {
      axios
        .get('/stats/users/' + this.statsStore.currentStatsUserId + '/avg', {
          params: {
            cube: 'ChallengeTrials',
            avgName: 'avgScore',
            limit: 100,
          },
        })
        .then((response) => {
          this.cubeData = response.data.data[0];
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    ...mapStores(useStatsStore),
    cubeCount: function () {
      return this.cubeData[this.dataKey]
        ? Math.floor(this.cubeData[this.dataKey]) || 0
        : 0;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style></style>
