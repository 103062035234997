<template>
  <v-app>
    <v-app-bar
      color="secondaryDark"
      dark
      app
      clipped-left
      height="56"
      v-if="authStore.isLoggedIn">
      <v-toolbar-title class="d-flex">
        <v-img
          class="pr-3"
          src="@/assets/icon-test-brain.png"
          width="31"
          height="27" />
        <span class="pl-3">Corporate Digitool</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <span> {{ authStore.user.firstName }} </span>
      <profile-menu />
    </v-app-bar>
    <sidebar v-if="authStore.isLoggedIn" />
    <v-main style="padding: 66px 0px 0px 56px;">
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Sidebar from './components/SidebarMenu.vue';
import ProfileMenu from './components/ProfileMenu.vue';
import { useAuthStore } from './store/auth';
import { mapStores } from 'pinia';

export default {
  name: 'App',
  components: {
    Sidebar,
    ProfileMenu,
  },

  data: () => ({
    //
  }),
  computed: {
    ...mapStores(useAuthStore),
  },
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Bungee+Shade&family=Madimi+One&family=Silkscreen:wght@400;700&display=swap');
@import '@/assets/css/style.css';

::v-deep .v-application--wrap {
  min-height: fit-content;
}
</style>
