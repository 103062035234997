<template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-account-circle</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in items" :key="index">
          <v-list-item-title>
            <v-btn @click="profile(item.value)" :outlined="item.style === 'outlined'" :text="item.style === 'text'">{{
              item.title
            }}</v-btn></v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { useAuthStore } from '../store/auth';
import { mapStores } from 'pinia';
export default {
  data: () => ({
    items: [{ title: 'Profil', value: 'profile', style:'text' }, { title: 'Se déconnecter', value: 'logout', style:'outlined' }],
  }),
  computed: {
    ...mapStores(useAuthStore),
  },
  methods: {
    async profile(message) {
      if (message === 'logout') {
        await this.authStore.logout();
        this.$router.push('/login');
      } else {
        this.$router.push('/profile');
      }
    },
  },
};
</script>
