import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
window._ = require('lodash');

Vue.use(Vuetify);

export default new Vuetify(
    {
        theme: {
          themes: {
            light:  {
                primary: "#3FA7D6",
                secondary: "#1f3a50",
                secondaryDark : "#06233b",
                accent: "#72aa87",
                error: "#FD151B",
                warning: "#FFB30F",
                info: "#3f51b5",
                success: "#4caf50",
                yellow: "#F3DE8A",
                green: "#57A773",
                pink: "#EE6352",
                orange: "#EEB162",
                }
          },
        },
      }
);
