import Vue from 'vue';
import App from './App.vue';

import vuetify from './plugins/vuetify';
import router from './router';
import pinia from './plugins/pinia';
import { useAuthStore } from './store/auth';
import axios from 'axios';
import VueAxios from 'vue-axios';
import rolePermissionMixin from '../mixins/rolePermissionMixin';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import VueSvgGauge from 'vue-svg-gauge';
//import underscore from 'vue-underscore';
import VueLodash from 'vue-lodash'
import lodash from 'lodash'


//Axios config
axios.defaults.baseURL = process.env.VUE_APP_API_URL || 'http://localhost/api';
axios.defaults.headers.common['Content-Type'] = 'application/json';
const authStore = useAuthStore();
axios.defaults.responseType = 'json';
axios.interceptors.request.use(config => {
  const token = authStore.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
},
error => {
  return Promise.reject(error);
});

Vue.use(VueAxios, axios);
Vue.mixin(rolePermissionMixin);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(require('vue-moment'));
Vue.use(VueSvgGauge);
//Vue.use(underscore);
Vue.use(VueLodash, { lodash: lodash })


new Vue({
  vuetify,
  router,
  pinia,
  render: (h) => h(App),
}).$mount('#app');
