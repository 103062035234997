import axios from 'axios';

class CubeService {
  async getAvgByCategoryForUser(userId, payload) {
    const res = await axios.get('/stats/users/' + userId + '/avg', {
      params: payload,
    });

    return res.data.data;
  }
  async getUsersGlobalStats() {
    const res = await axios.get('stats/admin/users/');

    return res.data.data;
  }
}
export default new CubeService();
