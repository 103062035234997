<template>
  <v-card>
    <v-card-text class="text-subtitle-1">
      <v-icon class="mr-2">mdi-av-timer</v-icon>
      Votre temps moyen de résolution d'un challenge
    </v-card-text>
    <div class="d-flex justify-center pa-4">
      <div class="d-flex text-center align-items-center">
        <v-overlay :value="loading" absolute opacity="0.1">
          <v-progress-circular
            indeterminate
            color="white"></v-progress-circular>
        </v-overlay>
        <div class="text-h3">{{ duration }}</div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapStores } from 'pinia';
import { useStatsStore } from '@/store/stats';
import axios from 'axios';
export default {
  data() {
    return {
      cubeData: [],
      duration: '0',
      loading: true,
    };
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    'statsStore.currentStatsUserId': 'fetchData',
  },
  methods: {
    fetchData() {
      axios
        .get(`/stats/users/${this.statsStore.currentStatsUserId}/avg`, {
          params: {
            cube: 'ChallengeTrials',
            dimensions: ['Users.firstName'],
            avgName: 'duration',
            limit: 100,
          },
        })
        .then((response) => {
          this.loading = false;
          if(response.data.data){
            this.cubeData = response.data.data;
            const durationInSeconds = Math.floor(this.cubeData[0]['ChallengeTrials.duration']);
            const minutes = Math.floor(durationInSeconds / 60);
            const seconds = durationInSeconds % 60;
            this.duration = `${minutes} min ${seconds} sec`;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  computed: {
    ...mapStores(useStatsStore),
  },
};
</script>

<style></style>
