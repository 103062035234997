<template>
  <v-card>
    <v-container>
      <v-card-text>
        <v-form ref="form" v-model="formIsValid" class="inputs-py-0">
          <v-row>
            <v-col cols="12">
              <!-- Title -->
              <v-text-field
                v-model="challengeStore.currentChallengeData.name"
                label="Nom du challenge"
                required
                :rules="[rules.required]"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <!-- Description -->
              <v-textarea
                v-model="challengeStore.currentChallengeData.description"
                label="Descritpion du challenge (230 caractères max)"
                auto-grow
                counter
                maxlength="230"
                rows="1"
                required
                :rules="[rules.required, rules.maxlength]"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
              <!-- Active -->
              <v-switch
                v-model="challengeStore.currentChallengeData.isActive"
                inset
                label="Actif"></v-switch>
            </v-col>
            <v-col cols="6" md="2">
              <v-text-field
                v-model="challengeStore.inputDuration"
                label="Durée estimée"
                type="time"
                suffix="mm:ss"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!-- add multiple file <input-->
            <v-col cols="12">
              <v-file-input
                v-model="challengeStore.currentChallengeData.documents"
                label="Téléverser vos documents"
                multiple
                chips
                prepend-icon="mdi-paperclip"
                accept=".doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .png"
                >
              </v-file-input>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-spacer></v-spacer>
            <v-col
              cols="2"
              md="4"
              class="d-flex align-items-center justify-content-end">
              <!-- Save button -->
              <v-btn
                :disabled="!formIsValid"
                @click="saveChallenge()"
                color="primary"
                fab
                small>
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import { useChallengeStore } from '@/store/challenge';
import { mapStores } from 'pinia';
import _ from 'lodash';
import moment from 'moment';
import 'moment-duration-format';

export default {
  data() {
    return {
      moment: moment,
      search: null,
      challenge: null,
      rules: {
        required: (value) =>
          !!value || value === 0 || 'Cette valeur est requise',
        requiredArray: (value) =>
          value.length > 0 || 'Cette valeur est requise',
        maxlength: (value) => value.length <= 230 || 'Max 230 characters',
      },
      formIsValid: true,
    };
  },

  mounted() {
    this.challengeStore.currentChallengeData;
    if (this.hasCurrentChallengeDuration) {
      this.challengeStore.inputDuration = moment
        .duration(this.challengeStore.currentChallengeData.duration, 'seconds')
        .format('mm:ss');
    } else {
      this.challengeStore.inputDuration = '00:00';
    }
  },

  methods: {
    saveChallenge() {
      this.$refs.form.validate();
      if (!this.formIsValid) {
        return;
      }

      const formData = new FormData();
      formData.append('name', this.challengeStore.currentChallengeData.name);
      formData.append(
        'description',
        this.challengeStore.currentChallengeData.description
      );
      formData.append(
        'isActive',
        this.challengeStore.currentChallengeData.isActive ? 1 : 0
      );
      formData.append(
        'tasks',
        JSON.stringify(this.challengeStore.currentChallengeData.tasks)
      );
      //todo dowload file to populate select and allow to only add not replace files
      if (this.challengeStore.currentChallengeData.documents.length > 0) {
        this.challengeStore.currentChallengeData.documents.forEach((file) => {
          if (file instanceof File) {
            formData.append('documents[]', file);
          }
        });
      }
      formData.append('duration', this.challengeDuration);

      console.log(formData);
      if (this.isEditingExistingChallenge) {
        formData.append('_method', 'PATCH');
        this.challengeStore.updateChallenge(
          this.challengeStore.currentChallengeData.id,
          formData
        );
        this.updateViewedChallenges();
        this.closeChallengeDialog();
      } else {
        this.challengeStore.createChallenge(formData);
        this.closeChallengeDialog();
      }
    },

    closeChallengeDialog() {
      if (!this.challengeStore.currentChallengeData.tasks == []) {
        this.challengeStore.showChallengeDialog = false;
      }
    },

    updateViewedChallenges() {
      const challengeIndex = _.findIndex(this.challengeStore.challenges, {
        id: this.challengeStore.currentChallengeData.id,
      });
      this.challengeStore.challenges[challengeIndex] =
        this.challengeStore.currentChallengeData;

      // Workaround to force the parent v-data-table to update its data
      this.challengeStore.challenges = _.clone(this.challengeStore.challenges);
    },
  },
  computed: {
    ...mapStores(useChallengeStore),

    isEditingExistingChallenge() {
      return this.challengeStore.currentChallengeData.id !== null;
    },

    hasCurrentChallengeDuration() {
      return this.challengeStore.currentChallengeData.duration !== null;
    },
    isDialogOpen() {
      return this.challengeStore.showChallengeDialog;
    },
    challengeDuration() {
      var timeArray = this.challengeStore.inputDuration.split(':');
      var duration = moment.duration(timeArray[0], 'minutes');
      duration.add(timeArray[1], 'seconds');

      return duration.asSeconds();
    },
  },

  watch: {
    isDialogOpen: {
      handler(isOpen) {
        if (isOpen) {
          this.challengeStore.currentChallengeData;
        }
      },
    },
  },
};
</script>

<style>
.theme--light.v-text-field > .v-input__control > .v-input__slot::before {
  border-color: rgba(204, 204, 204, 0.42) !important;
}

.v-text-field__details {
  display: none !important;
}

.drag-icon {
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: rgba(1, 1, 1, 0.2);
}

.draggable-item {
  cursor: grab;
}

.v-input__control label {
  top: 5px;
}
</style>
