<template>
  <v-dialog v-model="dialog" max-width="950px">
    <v-card>
      <v-toolbar color="secondary" dark>
        <v-toolbar-title>Statistiques de : {{ userDetails['Users.firstName']}}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <PreviousChallengesView :isAdminViewProp="true" v-if="!loading"/>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="closeModal()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//TODO: refactor into component... cuz brother ewww
import PreviousChallengesView from '@/views/PreviousChallengesView.vue'

export default {
  components : { PreviousChallengesView },
  props: {
    dialogProp: {
      type: Boolean,
      default: false
    },
    userDetails: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    closeModal() {
      this.dialog = false;
    }
  },
  computed: {
    dialog: {
      get() {
        //hack allows data to load
        if(this.dialogProp){
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.loading = false;
        }
        return this.dialogProp;
      },
      set(value) {
        this.loading = true;
        this.$emit('update:dialogProp', value);
      }
    }
  },
};
</script>

<style scoped>
/* Styles for the modal */
</style>
