var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.challengeStore.currentChallengeData.tasks.length)?_c('v-stepper',{attrs:{"alt-labels":""},model:{value:(_vm.etape),callback:function ($$v) {_vm.etape=$$v},expression:"etape"}},[[_c('v-stepper-header',[(_vm.challengeStore.hasIntroStep)?_c('v-stepper-step',{key:"intro-step",attrs:{"complete":_vm.etape > 0,"step":"0"}},[_vm._v(" Instructions ")]):_vm._e(),(_vm.challengeStore.hasIntroStep)?_c('v-divider'):_vm._e(),_vm._l((_vm.challengeStore.currentChallengeData.tasks.length),function(task){return [_c('v-stepper-step',{key:`${task}-step`,attrs:{"complete":_vm.etape > task,"step":task}},[_vm._v(" Tâche "+_vm._s(task)+" ")]),(
              task !== _vm.challengeStore.currentChallengeData.tasks.length + 1
            )?_c('v-divider',{key:task}):_vm._e()]}),_c('v-stepper-step',{key:`${
            _vm.challengeStore.currentChallengeData.tasks.length + 1
          }-step`,attrs:{"complete":_vm.etape > _vm.challengeStore.currentChallengeData.tasks.length + 1,"step":_vm.challengeStore.currentChallengeData.tasks.length + 1}},[_vm._v(" Récapitulatif ")]),_c('v-col',{staticClass:"ma-auto",attrs:{"cols":"auto"}},[_c('v-card',{attrs:{"elevation":"3"}},[_c('v-card-title',{staticClass:"pa-2"},[_c('p',{staticClass:"text-h5 mb-0 mr-1"},[_vm._v(_vm._s(_vm.minutes)+":"+_vm._s(_vm.seconds))]),_c('v-icon',[_vm._v("mdi-clock")])],1)],1)],1)],2),(_vm.challengeStore.currentChallengeData.tasks)?_c('v-stepper-items',[_vm._l((_vm.challengeStore.currentChallengeData.tasks
            .length),function(taskIndex){return _c('v-stepper-content',{key:`${taskIndex}-content`,attrs:{"step":taskIndex}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.nextStep(taskIndex)}}},[_c('v-card',{staticClass:"mb-0",attrs:{"elevation":"0"}},[_c('v-card-title',[_c('h3',{staticClass:"headline mb-0"},[_vm._v(" "+_vm._s(_vm.challengeStore.currentChallengeData.tasks[taskIndex - 1] .title)+" ")])]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.challengeStore.currentChallengeData.tasks[taskIndex - 1] .description)+" ")]),_c('v-text-field',{staticClass:"ps-3 pr-3",attrs:{"label":"Réponse","required":"","outlined":""},model:{value:(
                  _vm.challengeStore.currentChallengeData.tasks[taskIndex - 1]
                    .answer_text
                ),callback:function ($$v) {_vm.$set(_vm.challengeStore.currentChallengeData.tasks[taskIndex - 1]
                    , "answer_text", $$v)},expression:"\n                  challengeStore.currentChallengeData.tasks[taskIndex - 1]\n                    .answer_text\n                "}})],1),_c('v-btn',{staticClass:"ml-3 mt-6",attrs:{"type":"submit","color":"primary"}},[_vm._v(" Soumettre ")])],1)])}),_c('v-stepper-content',{key:"intro-content",attrs:{"step":"0"}},[_c('v-card',{staticClass:"mb-0",attrs:{"elevation":"0"}},[_c('v-card-title'),_c('v-card-text',[(_vm.challengeStore.currentChallengeData.instruction)?_c('div',{staticClass:"mb-4"},[_c('h3',{staticClass:"headline mb-0 black--text"},[_vm._v("Instructions")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.challengeStore.currentChallengeData.instruction)}})]):_vm._e(),(
                  _vm.challengeStore.currentChallengeData.documents.length > 0
                )?_c('div',[_c('h3',{staticClass:"headline mb-0 black--text"},[_vm._v("Documents")]),_c('span',[_vm._v("Veillez à télécharger les documents ci-dessous avant de le démarrer le challenge.")]),_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.challengeStore.currentChallengeData
                      .documents),function(document){return _c('div',{key:document.id,staticClass:"d-flex flex-column align-items-center mr-4 mt-4"},[_c('v-btn',{staticClass:"mt-2",attrs:{"fab":"","small":"","color":"secondary"},on:{"click":function($event){return _vm.downloadDocument(document.id, document.name)}}},[_c('v-icon',[_vm._v("mdi-file")])],1),_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(document.name.length <= 12 ? document.name : document.name.slice(0, 12) + '...')+" ")])],1)}),0)]):_vm._e()])],1),_c('v-btn',{staticClass:"ml-3 mt-4",attrs:{"color":"primary"},on:{"click":function($event){return _vm.startChallengeAfterIntro()}}},[_vm._v(" C'est parti ! ")])],1),_c('v-stepper-content',{key:`${
            _vm.challengeStore.currentChallengeData.tasks.length + 1
          }-content`,attrs:{"step":_vm.challengeStore.currentChallengeData.tasks.length + 1}},[_c('v-alert',{attrs:{"value":true,"type":"success"}},[_vm._v(" Bravo ! Vous avez terminé le challenge ! ")]),_c('v-card',{staticClass:"mb-0",attrs:{"elevation":"0"}},[_c('v-card-title',[_c('h3',{staticClass:"headline mb-0"},[_vm._v("Votre récapitulatif")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-progress-circular',{attrs:{"rotate":-90,"size":250,"width":25,"value":_vm.challengeStore.score,"color":"primary"}},[_c('strong',[_vm._v(_vm._s(_vm.challengeStore.score)+"% de bonnes réponses")])])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-list',{staticClass:"pl-0"},_vm._l((_vm.challengeStore.currentChallengeData
                        .tasks),function(task){return _c('v-list-item',{key:task.id,staticClass:"pl-0"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(task.title)+" ")]),_c('v-list-item-subtitle',[(_vm.isTaskCorrectStringMatch(task))?_c('span',{staticClass:"text-green"},[_vm._v(" "+_vm._s(task.answer_text)+" "),_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-decagram")])],1):_c('span',{staticClass:"text-red-lighten-1"},[_vm._v(" "+_vm._s(task.answer_text)+" "),_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-box")])],1)])],1)],1)}),1)],1)],1)],1)],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.gobackhome()}}},[_vm._v(" Fermer ")])],1)],2):_vm._e()]],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }