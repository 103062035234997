//pinia store for stats
import { defineStore } from 'pinia';
import axios from 'axios';

export const useStatsStore = defineStore('stats', {
  state: () => {
    return {
      currentStatsUserId: null,
    };
  },
  actions: {
    setCurrentStatsUserId(userId){
        this.currentStatsUserId = userId;
    }
  },
});
