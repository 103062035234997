<template>
  <div>
    <v-stepper
      v-model="etape"
      alt-labels
      v-if="challengeStore.currentChallengeData.tasks.length">
      <template>
        <v-stepper-header>
          <v-stepper-step
            v-if="challengeStore.hasIntroStep"
            key="intro-step"
            :complete="etape > 0"
            step="0">
            Instructions
          </v-stepper-step>
          <v-divider v-if="challengeStore.hasIntroStep"></v-divider>
          <template
            v-for="task in challengeStore.currentChallengeData.tasks.length">
            <v-stepper-step
              :key="`${task}-step`"
              :complete="etape > task"
              :step="task">
              Tâche {{ task }}
            </v-stepper-step>
            <v-divider
              v-if="
                task !== challengeStore.currentChallengeData.tasks.length + 1
              "
              :key="task"></v-divider>
          </template>
          <v-stepper-step
            :key="`${
              challengeStore.currentChallengeData.tasks.length + 1
            }-step`"
            :complete="
              etape > challengeStore.currentChallengeData.tasks.length + 1
            "
            :step="challengeStore.currentChallengeData.tasks.length + 1">
            Récapitulatif
          </v-stepper-step>
          <v-col cols="auto" class="ma-auto">
            <v-card elevation="3">
              <v-card-title class="pa-2">
                <p class="text-h5 mb-0 mr-1">{{ minutes }}:{{ seconds }}</p>
                <v-icon>mdi-clock</v-icon>
              </v-card-title>
            </v-card>
          </v-col>
        </v-stepper-header>
        <v-stepper-items v-if="challengeStore.currentChallengeData.tasks">
          <v-stepper-content
            v-for="taskIndex in challengeStore.currentChallengeData.tasks
              .length"
            :key="`${taskIndex}-content`"
            :step="taskIndex">
            <form @submit.prevent="nextStep(taskIndex)">
              <v-card class="mb-0" elevation="0">
                <v-card-title>
                  <h3 class="headline mb-0">
                    {{
                      challengeStore.currentChallengeData.tasks[taskIndex - 1]
                        .title
                    }}
                  </h3>
                </v-card-title>
                <v-card-text>
                  {{
                    challengeStore.currentChallengeData.tasks[taskIndex - 1]
                      .description
                  }}
                </v-card-text>
                <v-text-field
                  v-model="
                    challengeStore.currentChallengeData.tasks[taskIndex - 1]
                      .answer_text
                  "
                  class="ps-3 pr-3"
                  label="Réponse"
                  required
                  outlined>
                </v-text-field>
              </v-card>
              <v-btn
                type="submit"
                color="primary"
                class="ml-3 mt-6">
                Soumettre
              </v-btn>
            </form>
          </v-stepper-content>

          <!-- the intro step content -->
          <v-stepper-content key="intro-content" step="0">
            <v-card class="mb-0" elevation="0">
              <v-card-title> </v-card-title>
              <v-card-text>
                <div
                  v-if="challengeStore.currentChallengeData.instruction"
                  class="mb-4">
                  <h3 class="headline mb-0 black--text">Instructions</h3>
                  <span
                    v-html="challengeStore.currentChallengeData.instruction">
                  </span>
                </div>
                <div
                  v-if="
                    challengeStore.currentChallengeData.documents.length > 0
                  ">
                  <h3 class="headline mb-0 black--text">Documents</h3>
                  <span
                    >Veillez à télécharger les documents ci-dessous avant de le
                    démarrer le challenge.</span
                  >
                  <div class="d-flex flex-wrap">
                    <div
                      class="d-flex flex-column align-items-center mr-4 mt-4"
                      v-for="document in challengeStore.currentChallengeData
                        .documents"
                      :key="document.id">
                      <v-btn
                        class="mt-2"
                        fab
                        small
                        @click="downloadDocument(document.id, document.name)"
                        color="secondary">
                        <v-icon>mdi-file</v-icon>
                      </v-btn>
                      <div class="d-flex">
                        {{
                          document.name.length <= 12
                            ? document.name
                            : document.name.slice(0, 12) + '...'
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
            <v-btn
              color="primary"
              class="ml-3 mt-4"
              @click="startChallengeAfterIntro()">
              C'est parti !
            </v-btn>
          </v-stepper-content>
          <v-stepper-content
            :key="`${
              challengeStore.currentChallengeData.tasks.length + 1
            }-content`"
            :step="challengeStore.currentChallengeData.tasks.length + 1">
            <v-alert :value="true" type="success">
              Bravo ! Vous avez terminé le challenge !
            </v-alert>
            <!-- Add recap of the answers -->
            <v-card class="mb-0" elevation="0">
              <v-card-title>
                <h3 class="headline mb-0">Votre récapitulatif</h3>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" class="text-center">
                    <v-progress-circular
                      :rotate="-90"
                      :size="250"
                      :width="25"
                      :value="challengeStore.score"
                      color="primary">
                      <strong
                        >{{ challengeStore.score }}% de bonnes réponses</strong
                      >
                    </v-progress-circular>
                  </v-col>
                  <v-col cols="12">
                    <v-list class="pl-0">
                      <v-list-item
                        class="pl-0"
                        v-for="task in challengeStore.currentChallengeData
                          .tasks"
                        :key="task.id">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ task.title }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <span
                              v-if="isTaskCorrectStringMatch(task)"
                              class="text-green">
                              {{ task.answer_text }}
                              <v-icon color="green"
                                >mdi-check-decagram</v-icon
                              ></span
                            >
                            <span v-else class="text-red-lighten-1">
                              {{ task.answer_text }}
                              <v-icon color="red">mdi-close-box</v-icon></span
                            >
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-btn color="primary" @click="gobackhome()"> Fermer </v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </template>
    </v-stepper>
  </div>
</template>

<script>
import { useChallengeStore } from '../store/challenge';
import { mapStores } from 'pinia';
export default {
  data() {
    return {
      etape: 0,
      minutes: '00',
      seconds: '00',
      interval: null,
    };
  },

  mounted() {
    if (!this.challengeStore.hasIntroStep) {
      this.startChallenge();
    }
  },

  computed: {
    ...mapStores(useChallengeStore),
  },

  methods: {
    downloadDocument(id, name) {
      this.challengeStore.getChallengeDocuments(id, name);
    },
    startChallenge() {
      this.startTimer();
      this.etape += 1;
    },
    startChallengeAfterIntro() {
      this.startChallenge();
    },
    async nextStep(taskIndex) {
      console.log('nextStep called');

      // add the answer of the task
      const answerDTO = {
        validation_type:
          this.challengeStore.currentChallengeData.tasks[taskIndex - 1]
            .validation.validation_type,
        answer_text:
          this.challengeStore.currentChallengeData.tasks[taskIndex - 1]
            .answer_text,
      };
      await this.challengeStore.answerChallengeTasks(
        this.challengeStore.currentChallengeData.id,
        this.challengeStore.currentChallengeData.tasks[taskIndex - 1].id,
        answerDTO
      );
      // add the next start tasks
      if (taskIndex < this.challengeStore.currentChallengeData.tasks.length) {
        this.challengeStore.startChallengeTasks(
          this.challengeStore.currentChallengeData.id,
          this.challengeStore.currentChallengeData.tasks[taskIndex].id
        );
      }

      this.etape = taskIndex + 1;
      if (
        this.etape ==
        this.challengeStore.currentChallengeData.tasks.length + 1
      ) {
        this.challengeStore
          .completeChallenge(this.challengeStore.currentChallengeData.id)
          .then(() => {
            this.interval = clearInterval(this.interval);
          });
      }
    },
    gobackhome() {
      this.$router.push({ name: 'home' });
    },
    //TODO : return result from api
    isTaskCorrectStringMatch(task) {
      return task.answer_text === task.validation.answer_text;
    },
    startTimer() {
      this.challengeStore
        .startChallenge(this.challengeStore.currentChallengeData.id)
        .then(() => {
          this.interval = setInterval(() => {
            if (this.seconds < 59) {
              this.seconds++;
              this.seconds = ('000' + this.seconds).slice(-2);
            } else if (this.seconds == 59) {
              this.minutes++;
              this.minutes = ('000' + this.minutes).slice(-2);
              this.seconds = 0;
            } else {
              clearInterval(this.interval);
            }
          }, 1000);
          this.challengeStore.startChallengeTasks(
            this.challengeStore.currentChallengeData.id,
            this.challengeStore.currentChallengeData.tasks[0].id
          );
        });
    },
  },
};
</script>

<style>
.v-text-field {
  width: 50%;
}
</style>
