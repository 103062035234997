import CompetenceService from '../services/competence.service';
import { defineStore } from 'pinia';
import _ from 'lodash';

export const useCompetenceStore = defineStore('competence', {
  state: () => ({
    isLoading: {
      competences: true,
      competencesCategories: true,
    },
    competences: [],
    competencesCategories: [],
  }),

  getters: {},

  actions: {
    getSelectableCompetences() {
      CompetenceService.getSelectableCompetences().then((response) => {
        this.competences = response.data;
        this.isLoading.competences = false;
      });
    },
    getCompetencesCategories() {
      CompetenceService.getCompetencesCategories().then((response) => {
        this.competencesCategories = response.data;
        this.isLoading.competencesCategories = false;
      });
    },
  },
});
