import AuthService from '../services/auth.service';
import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
  state: () => {
    let user = null;
    let token = null;
    let accessCode = null;
    const cookieUser = getCookie('user');
    const cookieToken = getCookie('token');
    if (cookieToken && cookieUser) {
      token = JSON.parse(cookieToken);
      user = JSON.parse(cookieUser);
    }
    return {
      user,
      token,
      accessCode,
    };
  },
  getters: {
    isLoggedIn() {
      return !!this.user;
    },
    roles() {
      if (this.user) {
        return this.user.roles;
      }
      return [];
    },
    isManagerOrAdmin() {
      return this.roles.some(obj => (obj.id === 1 || obj.id === 3));
    },
  },
  actions: {
    setAccessCode(accessCode) {
      this.accessCode = accessCode;
    },
    login(email, password) {
      return AuthService.login(email, password)
        .then((response) => {
          if (response.data.token) {
            setCookie('user', JSON.stringify(response.data.user), 7);
            setCookie('token', JSON.stringify(response.data.token), 7);
            this.user = response.data.user;
            this.token = response.data.token;
          }
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    loginWithAccessCode() {
      return AuthService.loginWithAccessCode(this.accessCode)
        .then((response) => {
          if (response.data.token) {
            setCookie('user', JSON.stringify(response.data.user), 7);
            setCookie('token', JSON.stringify(response.data.token), 7);
            this.user = response.data.user;
            this.token = response.data.token;
          }
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    logout() {
      return AuthService.logout().then(() => {
        this.user = null;
        this.token = null;
        this.accessCode = null;
        deleteCookie('user');
        deleteCookie('token');
      });
    },
    register(newUserDTO) {
      return AuthService.register(newUserDTO)
        .then((response) => {
          if (response.data.token) {
            setCookie('user', JSON.stringify(response.data.user), 7);
            setCookie('token', JSON.stringify(response.data.token), 7);
            this.user = response.data.user;
            this.token = response.data.token;
          }
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
});

// Helper functions

function setCookie(name, value, days) {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=/';
}

function getCookie(name) {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) {
    return decodeURIComponent(match[2]);
  }
  return null;
}

function deleteCookie(name) {
  setCookie(name, '', -1);
}
