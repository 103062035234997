import { useAuthStore } from '../src/store/auth.js';
import { mapStores } from 'pinia';
import _ from 'lodash';
export default {
  computed: {
    ...mapStores(useAuthStore),
  },
  methods: {
    hasRole(roleName) {
      return _.find(this.authStore.roles, { name: roleName });
    },
  },
};
