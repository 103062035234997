<template>
  <v-progress-linear
    color="primary"
    indeterminate
    height="7"
    v-show="load"
  ></v-progress-linear>
</template>

<script>
export default {
  props: ['load'],
}
</script>
<style></style>
