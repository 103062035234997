<template>
  <div>
    <v-app>
      <v-main>
        <v-container fill-height style="height: 100vh">
          <v-layout align-center justify-center>
            <v-flex xs12 sm8 md6>
              <loading :load="setLoad" />
              <v-card class="elevation-12">
                <v-toolbar dark color="secondary">
                  <v-toolbar-title>Connexion</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <form ref="form" @submit.prevent="handleLogin">
                    <v-text-field
                      v-model="user.email"
                      name="email"
                      label="E-mail"
                      type="email"
                      placeholder="E-mail"
                      required
                    ></v-text-field>
                    <v-text-field
                      v-model="user.password"
                      name="password"
                      label="Mot de passe"
                      type="password"
                      placeholder="Mot de passe"
                      required
                    ></v-text-field>
                    <div class="red--text">{{ errorMessage }}</div>
                    <v-btn
                      :disabled="setLoad"
                      type="submit"
                      class="mt-4"
                      color="primary"
                      value="log in"
                      >Se connecter</v-btn
                    >
                    <div
                      class="grey--text mt-4"
                      v-on:click="$router.push('/register')"
                    >
                      <div class="grey--text mt-4">
                        Vous n'avez pas de compte ? <strong>S'inscrire</strong>
                      </div>
                    </div>
                  </form>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import User from '../models/user'
import Loading from '../components/Loading.vue'
import { useAuthStore } from '../store/auth'
import { mapStores } from "pinia";
export default {
  name: 'LoginView',
  data() {
    return {
      user: new User('', '', '', ''),
      registerForm: {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
      },
      errorMessage: '',
      setLoad: false,
    }
  },
  mounted() {
    if(this.authStore.isLoggedIn) {
      this.$router.push('/');
    }
    console.log("TOKEN" + this.authStore.accessCode);
    if (this.authStore.accessCode) {
      this.setLoad = true
      //login with access_token 
      this.authStore.loginWithAccessCode()
        .then(() => {
          this.$router.push('/')
        })
        .catch((error) => {
          this.errorMessage = error.response.data.message
        })
        .finally(() => {
            this.setLoad = false
          })
    }
  },
  computed: {
    ...mapStores(useAuthStore),
  },
  methods: {
    handleLogin() {
      this.setLoad = true
      if (this.user.email && this.user.password) {
        this.authStore.login(this.user.email, this.user.password)
          .then(() => {
            this.$router.push('/')
          })
          .catch((error) => {
            this.errorMessage = error.response.data.message
          })
          .finally(() => {
            this.setLoad = false
          })
      }
    },
  },
  components: {
    Loading,
  },
}
</script>
