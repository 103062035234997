import axios from 'axios';

class TaskService {
  async getTasksByChallengeId(challengeId) {
    const res = await axios({
      url: '/challenges/' + challengeId + '/tasks',
      method: 'GET',
    });
    return res;
  }

  async createTask(challengeId,task) {
    const res = await axios({
      url: '/challenges/' + challengeId + '/tasks',
      method: 'POST',
      data: task,
    });
    return res;
  }

  async updateTask(challengeId, taskId, task) {
    const res = await axios({
      url: '/challenges/' + challengeId + '/tasks/' + taskId,
      method: 'PATCH',
      data: task,
    });
    return res;
  }

  async updateTasks(challengeId, tasks) {
    const res = await axios({
      url: '/challenges/' + challengeId + '/tasks/bulk',
      method: 'PATCH',
      data: tasks,
    });
    return res;
  }

  async deleteTask(challengeId, taskId) {
    const res = await axios({
      url: '/challenges/' + challengeId + '/tasks/' + taskId,
      method: 'DELETE',
    });
    return res;
  }

  async startTask(challengeId, taskId) {
    const res = await axios({
      url: '/challenges/' + challengeId + '/tasks/' + taskId + '/start',
      method: 'POST',
    });
    return res;
  }

  async answerTask(challengeId, taskId) {
    const res = await axios({
      url: '/challenges/' + challengeId + '/tasks/' + taskId + '/answer',
      method: 'POST',
    });
    return res;
  }
}
export default new TaskService();
