<template>
  <v-container fluid>
    <h1 class="v-heading secondary-font text-secondary text-h3 text-sm-h3 mb-6">Mes challenges</h1>
    <v-row>
      <v-col cols="12" md="4" offset-md="4">
        <!-- select multiple with selected tags and chip color secondary -->
        <v-select
          v-model="tagsStore.selectedTags"
          :items="tagsStore.tags"
          item-text="name"
          item-value="id"
          label="Filtrer par tags"
          multiple
          chips
          clearable
          hide-details
          outlined
          class="w-100"
          prepend-icon="mdi-filter">
          <template #selection="{ item }">
            <v-chip color="secondary">{{ item.name }}</v-chip>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" md="4">
        <!-- select with sort type -->
        <v-select
          v-model="sortType"
          :items="sortTypes"
          item-text="name"
          item-value="value"
          label="Trier par"
          clearable
          hide-details
          outlined
          class="w-100"
          prepend-icon="mdi-sort"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <CategoriesLegends />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="12"
        lg="6"
        v-for="challenge in sortedChallenges"
        :key="challenge.id">
        <challenge-card :challenge="challenge" />
      </v-col>
    </v-row>
    <div v-if="false" class="loader-container d-flex justify-center align-center">
      <v-progress-circular color="grey" indeterminate :size="200" :width="6"></v-progress-circular>
    </div>
  </v-container>
</template>

<script>
import { useAuthStore } from '../store/auth';
import { mapStores } from 'pinia';
import { useChallengeStore } from '@/store/challenge';
import { useTagsStore } from '@/store/tags';
import ChallengeCard from '../components/ChallengeCard.vue';
import CategoriesLegends from '../components/CategoriesLegends.vue';

export default {
  name: 'HomeView',
  data() {
    return {
      sortType : null,
      sortTypes : [
        { value : 'name', name : 'Nom'},
        { value : 'latest', name : 'Plus récent'},
        { value : 'oldest', name : 'Plus vieux'},
      ]
    };
  },

  mounted() {
    this.challengeStore.getActiveChallenges();
    this.challengeStore.initialiseCurrentChallengeData();
    this.tagsStore.getTags();
  },

  computed: {
    ...mapStores(useAuthStore),
    ...mapStores(useChallengeStore),
    ...mapStores(useTagsStore),

    filteredChallenges() {
      if (this.tagsStore.selectedTags.length === 0) {
        return this.challengeStore.challenges;
      }
      //return challenges which have at least one tag in common with selected tags
      return this.challengeStore.challenges.filter((challenge) => {
        return challenge.tags.some((tag) =>
          this.tagsStore.selectedTags.includes(tag.id)
        );
      });
    },

    sortedChallenges(){
      let sortedChallenges = [...this.filteredChallenges];
      if(this.sortType){
        switch(this.sortType){
          case 'latest' : 
            sortedChallenges = _.orderBy(sortedChallenges, ['created_at', 'name'], ['desc', 'asc']);
            break;
          case 'oldest' : 
            sortedChallenges = _.orderBy(sortedChallenges, ['created_at', 'name'], ['asc', 'asc']);
            break;
          case 'name' : 
            sortedChallenges = _.orderBy(sortedChallenges, [challenge => challenge.name.toLowerCase()]);
            break;
          default: 
            break;
        } 
      }
      return sortedChallenges;
    }, 
  },
  components: { ChallengeCard, CategoriesLegends },
};
</script>

<style scoped>
::v-deep .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections {
    min-height: 0px;
}
.secondary-font  {
    /*font-family: "Silkscreen", sans-serif !important;*/
    font-family: "Bungee Shade", sans-serif !important;
}
.text-secondary {
  color: #1f3a50 !important;
}
.loader-container {
  min-height: 24em;
}
</style>