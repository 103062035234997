<template lang="">
  <div>
    <div v-if="!loading">
      <v-row>
        <v-col>
          <blockquote cite="">
            <p class="text-h5 font-weight-light">
              « Enseigner ce n’est pas remplir un vase, c’est allumer un feu »
            </p>
            <footer>—Aristophane</footer>
          </blockquote>
        </v-col>
      </v-row>
      <v-row style="margin-top: -2rem;">
        <v-col v-for="(data, index) in cubeData" :key="index" v-if="cubeData.length > 0">
          <UserCompetenceFireChart
            :competenceId="data['CompetencesCategories.id']"
            :successPercent="data['TaskTrials.avgScore']"
            :color="getCategoryColor(data['CompetencesCategories.id'])"
            :label="getCategoryName(data['CompetencesCategories.id'])">
          </UserCompetenceFireChart>
        </v-col>
        <v-col v-if="cubeData.length == 0 && !loading">
          <AlertNoDataAvailable></AlertNoDataAvailable>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex justify-content-center mt-6" v-else>
        <v-overlay :value="loading" absolute opacity="0.1">
          <v-progress-circular
            indeterminate
            color="white"></v-progress-circular>
        </v-overlay>
      </div>
  </div>
</template>
<script>
import { mapStores } from 'pinia';
import { useCompetenceStore } from '@/store/competence';
import { useStatsStore } from '@/store/stats';
import cubeService from '@/services/cube.service';
import UserCompetenceFireChart from './UserCompetenceFireChart.vue';
import AlertNoDataAvailable from '@/components/AlertNoDataAvailable.vue';

export default {
  components: { UserCompetenceFireChart, AlertNoDataAvailable},
  data() {
    return {
      cubeData: [],
      loading: true,
    };
  },
  mounted() {
    this.competenceStore.getCompetencesCategories();
    this.fetchData();
  },
  watch: {
    'statsStore.currentStatsUserId': 'fetchData',
  },
  computed: {
    ...mapStores(useCompetenceStore),
    ...mapStores(useStatsStore),
  },
  methods: {
    fetchData() {
      cubeService
        .getAvgByCategoryForUser(this.statsStore.currentStatsUserId, {
          cube: 'TaskTrials',
          dimensions: ['CompetencesCategories.id'],
          limit: 100,
          avgName: 'avgScore',
        })
        .then((response) => {
          this.cubeData = response;
          this.loading = false;
        });
    },
    //2 methods duplicated from MultiLinesChart TODO refactor
    getCategoryColor(id) {
      const category = this.competenceStore.competencesCategories.find(
        (c) => c['id'] == id
      );
      return category
        ? category.color == 'primary'
          ? 'lightBlue'
          : category.color
        : `rgb(${Math.random() * 255}, ${Math.random() * 255}, ${
            Math.random() * 255
          })`;
    },
    getCategoryName(id) {
      const category = this.competenceStore.competencesCategories.find((c) => {
        return c['id'] == id;
      });
      return category ? category.name : id;
    },
  },
};
</script>
<style lang=""></style>
