<template>
  <div>
    <v-container>
      <!-- Create new Challenge button -->
      <div class="sticky fixedbottom">
        <v-btn
          v-if="!challengeStore.isLoading.challenges"
          @click="openCreateChallengeDialog()"
          fab
          dark
          medium
          color="primary"
          elevation="6">
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
      </div>

      <v-card>
        <!-- Channels table -->
        <v-data-table
          :headers="headers"
          :items="challengeStore.challenges"
          :loading="challengeStore.isLoading.challenges"
          loading-text="Chargement des données en cours... Veuillez patienter"
          no-data-text="Aucune donnée actuellement disponible"
          :footer-props="{
            'items-per-page-text': 'Challenges par page',
          }">
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td>
                <v-icon :color="item.isActive ? 'accent' : 'red'">
                  mdi-circle
                </v-icon>
              </td>
              <td>
                <div v-if="!item.tasks_count == []">
                  {{ item.tasks_count }} {{ 'tâches ' }}
                </div>
                <div v-else>{{ '0 tâches ' }}</div>
                <v-btn
                  x-small
                  dark
                  class="mb-2"
                  color="primary"
                  @click="openTasksView(item)">
                  {{ 'Voir' }}
                  <v-icon class="ml-1 align-self-center" small>mdi-eye</v-icon>
                </v-btn>
              </td>
              <td>
                <v-btn
                  small
                  @click="openEditChallengeDialog(item)"
                  icon
                  color="primary"
                  class="mr-1">
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
                <v-btn
                  small
                  @click="openDeleteChallengeDialog(item)"
                  icon
                  color="red"
                  class="ml-1">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
        <!-- Creation/Edition dialog -->
        <v-dialog
          v-if="challengeStore.currentChallengeData"
          v-model="challengeStore.showChallengeDialog"
          transition="dialog-bottom-transition"
          max-width="900">
          <v-toolbar dark color="primary">
            <v-btn
              icon
              dark
              @click.stop="challengeStore.showChallengeDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <!-- Edit challenge title -->
            <v-toolbar-title v-if="!!challengeStore.currentChallengeData.id">
              {{ 'Edition du challenge :' }}
              {{ challengeStore.currentChallengeData.name }}
            </v-toolbar-title>
            <!-- Create challenge title -->
            <v-toolbar-title v-else>
              {{ "Création d'un challenge" }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <challenge-edition></challenge-edition>
        </v-dialog>
      </v-card>
      <!-- Deletion dialog -->
      <v-dialog
        v-model="challengeStore.showDeleteChallengeDialog"
        max-width="600px">
        <v-card>
          <v-card-title class="text-h5"
            >Êtes-vous sûr de vouloir supprimer ce challenge ?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="closeDelete"
              >Annuler</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteChallengeConfirm"
              >Valider</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import _ from 'lodash';
import { useChallengeStore } from '../store/challenge';
import { mapStores } from 'pinia';
import ChallengeEdition from '../components/ChallengeEdition.vue';
import { useTasksStore } from '@/store/tasks';

export default {
  components: {
    ChallengeEdition,
  },
  data() {
    return {
      headers: [
        {
          text: 'Nom',
          align: 'start',
          sortable: true,
          value: 'name',
          icon: 'mdi-open-in-new',
          width: '24%',
        },
        {
          text: 'Description',
          align: 'start',
          value: 'description',
          width: '45%',
        },
        {
          text: 'Actif',
          align: 'start',
          value: 'isActive',
          width: '10%',
        },
        {
          text: 'Tasks',
          align: 'start',
          value: 'tasks',
          width: '10%',
        },
        {
          text: 'Actions',
          align: 'start',
          value: 'active',
          width: '10%',
        },
      ],
    };
  },

  mounted() {
    this.challengeStore.getAllChallenges();
    this.challengeStore.showChallengeDialog = false;
  },

  computed: {
    ...mapStores(useChallengeStore),
    ...mapStores(useTasksStore),
  },

  methods: {
    openCreateChallengeDialog() {
      this.challengeStore.initialiseCurrentChallengeData();
      this.challengeStore.showChallengeDialog = true;
    },
    openEditChallengeDialog(challenge) {
      this.challengeStore.currentChallengeData =
        this.challengeStore.getChallenge(challenge.id);
      this.challengeStore.showChallengeDialog = true;
    },
    openDeleteChallengeDialog(challenge) {
      this.challengeStore.currentChallengeData = challenge;
      this.challengeStore.showDeleteChallengeDialog = true;
    },
    deleteChallengeConfirm() {
      this.challengeStore.deleteChallenge(
        this.challengeStore.currentChallengeData.id
      );
      this.deleteViewedChallenges();
      this.challengeStore.showDeleteChallengeDialog = false;
    },
    deleteViewedChallenges() {
      const challengeIndex = _.findIndex(this.challengeStore.challenges, {
        id: this.challengeStore.currentChallengeData.id,
      });
      this.challengeStore.challenges.splice(challengeIndex, 1);

      // Workaround to force the parent v-data-table to update its data
      this.challengeStore.challenges = _.clone(this.challengeStore.challenges);
    },
    closeDelete() {
      this.challengeStore.showDeleteChallengeDialog = false;
    },
    closeTasksDialog() {
      this.challengeStore.showChallengeDialog = false;
      this.challengeStore.showTasksDialog = false;
      this.challengeStore.initialiseCurrentChallengeData();
    },
    //method to redirect to TasksView.vue
    openTasksView(challenge) {
      this.challengeStore.currentChallengeData =
        this.challengeStore.getChallenge(challenge.id);
      this.tasksStore.tasks = this.tasksStore.getTasksByChallengeId(
        challenge.id
      );
      this.$router.push({
        name: 'tasks',
      });
    },
  },
};
</script>
<style></style>
