<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1 class="v-heading text-sm-h3 mb-4">Informations personnelles</h1>
      </v-col>
    </v-row>
    <div class="d-flex mt-8">
      <v-avatar color="primary" rounded size="72" class="white--text text-h4">{{ userInitials }}</v-avatar>
      <div class="d-flex flex-column ml-8">
         <div class="mb-4">
            <div class="v-subheading font-weight-bold">Prénom</div>
            <div class="v-body-1">{{ authStore.user?.firstName }}</div>
          </div>
        <div class="mb-4">
          <div class="v-subheading font-weight-bold">Nom</div>
          <div class="v-body-1">{{ authStore.user?.lastName }}</div>
        </div>
        <div class="mb-4">
          <div class="v-subheading font-weight-bold">E-mail</div>
          <div class="v-body-1">{{ authStore.user?.email }}</div>
        </div>
      </div>
    </div>
    <v-row justify="center" class="mt-8">
      <v-col cols="auto">
        <v-btn @click="logout()" color="secondary" outlined>Se déconnecter</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useAuthStore } from '@/store/auth';
import { mapStores } from 'pinia';

export default {
  name: 'ProfileView',
  mounted() {},
  data() {
    return {};
  },
  methods: {
    async logout() {
        await this.authStore.logout();
        this.$router.push('/login');
    },
  },
  computed: {
    ...mapStores(useAuthStore),
    userInitials(){
      return this.authStore.user.firstName?.charAt(0).toUpperCase() + this.authStore.user.lastName?.charAt(0).toUpperCase();
    },
  },

};
</script>

<style lang="scss" scoped></style>
