<template>
  <v-card>
    <v-card-text>
      <v-form ref="form" v-model="formIsValid" class="inputs-py-0">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="tasksStore.currentTasksData.title"
              label="Nom de la tâche"
              required
              :rules="[rules.required]">
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="tasksStore.currentTasksData.description"
              label="Descritpion de la tâche"
              required
              :rules="[rules.required]">
            </v-text-field>
          </v-col>
          <v-col md="8" cols="12" class="py-0">
            <v-select
              v-model="tasksStore.currentTasksData.competence_id"
              :items="competenceStore.competences"
              item-text="name"
              item-value="id"
              label="Select"
              persistent-hint
              single-line></v-select>
          </v-col>
          <v-col md="8" cols="12">
            <v-select
              v-model="tasksStore.currentTasksData.validation.validation_type"
              :items="tasksStore.validationItems"
              item-text="text"
              item-value="id"
              label="Select"
              persistent-hint
              single-line></v-select>
          </v-col>
          <v-col md="8" cols="12">
            <v-text-field
              v-model="tasksStore.currentTasksData.validation.answer_text"
              label="Entrez la réponse attendue"
              clearable
              :rules="[(v) => !!v || 'Une réponse est requise']"
              required>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-spacer></v-spacer>
          <v-col
            cols="2"
            md="4"
            class="d-flex align-items-center justify-content-end">
            <!-- Save button -->
            <v-btn
              @click="saveAddTasks()"
              color="accent"
              fab
              small
              dark
              :disabled="!formIsValid">
              <v-icon dark>mdi-content-save</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapStores } from 'pinia';
import { useTasksStore } from '@/store/tasks';
import { useChallengeStore } from '@/store/challenge';
import { useCompetenceStore } from '@/store/competence';

export default {
  data() {
    return {
      rules: {
        required: (value) =>
          !!value || value === 0 || 'Cette valeur est requise',
        requiredArray: (value) =>
          value.length > 0 || 'Cette valeur est requise',
      },
      formIsValid: true,
    };
  },

  mounted() {
    this.tasksStore.tasks;
    this.competenceStore.getSelectableCompetences();
  },

  methods: {
    saveAddTasks() {
      this.$refs.form.validate();
      if (!this.formIsValid) {
        return;
      }

      const tasksDTO = {
        title: this.tasksStore.currentTasksData.title,
        description: this.tasksStore.currentTasksData.description,
        order: this.tasksStore.tasks.length + 1,
        competence_id: this.tasksStore.currentTasksData.competence_id,
        validation: this.tasksStore.currentTasksData.validation,
      };

      this.tasksStore.createTask(
        this.challengeStore.currentChallengeData.id,
        tasksDTO
      );
      this.tasksStore.getTasksByChallengeId(
        this.challengeStore.currentChallengeData.id
      );
      this.closeAddTaskDialog();
    },

    closeAddTaskDialog() {
      this.tasksStore.showAddTasksDialog = false;
      this.tasksStore.initialiseCurrentTasksData();
    },
  },
  computed: {
    ...mapStores(useTasksStore),
    ...mapStores(useChallengeStore),
    ...mapStores(useCompetenceStore),

    isDialogOpen() {
      return this.tasksStore.showAddTasksDialog;
    },
  },

  watch: {
    isDialogOpen: {
      handler(isOpen) {
        if (isOpen) {
          this.tasksStore.currentTasksData;
        }
      },
    },
  },
};
</script>

<style>
.theme--light.v-text-field > .v-input__control > .v-input__slot::before {
  border-color: rgba(204, 204, 204, 0.42) !important;
}

.v-text-field__details {
  display: none !important;
}

.drag-icon {
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: rgba(1, 1, 1, 0.2);
}

.draggable-item {
  cursor: grab;
}

.v-input__control label {
  top: 5px;
}
</style>
