import axios from 'axios';

class AuthService {
  login(email, password) {
    return axios({
      url: '/login',
      method: 'POST',
      data: {
        email: email,
        password: password,
      },
    });
  }

  loginWithAccessCode(accessCode) {
    return axios({
      url: '/login/easy',
      method: 'POST',
      data: {
        accessCode: accessCode,
      },
    });
  }


  logout() {
    return axios({
      url: '/logout',
      method: 'POST',
    });
  }
  register(newUserDTO) {
    return axios({
      url: '/register',
      method: 'POST',
      data: {
        first_name: newUserDTO.firstName,
        last_name: newUserDTO.lastName,
        email: newUserDTO.email,
        password: newUserDTO.password,
        password_confirmation: newUserDTO.password_confirmation,
      },
    });
  }
}
export default new AuthService();
