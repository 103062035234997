<template>
  <v-container>
    <div class="sticky fixedtopleft">
      <v-btn
        @click="backChallenges"
        dark
        small
        color="primary"
        fab
        right
        elevation="2">
        <v-icon dark> mdi-arrow-left </v-icon>
      </v-btn>
    </div>
    <h4 class="mt-3">Challenge : {{ challengeStore.currentChallengeData.name }}</h4>
    <div v-if="tasksStore.tasks && tasksStore.tasks.length > 0">
      <v-expansion-panels>
        <v-row>
          <draggable id="tasks-draggable" v-model="tasksStore.tasks">
            <v-col
              v-for="(task, tIndex) in tasksStore.tasks"
              :key="tIndex"
              cols="12">
              <v-expansion-panel class="mt-2" value="1">
                <v-toolbar color="secondary" dense flat id="toolbar-tasks">
                </v-toolbar>
                <v-expansion-panel-header disable-icon-rotate>
                  <v-row class="pa-2">
                    <v-icon class="mr-3">mdi-drag-vertical</v-icon>
                    <span class="text-h6">
                      {{ 'Tâche ' }}
                      {{ tIndex + 1 }}
                      {{ task.title ? '- ' + task.title : '' }}
                    </span>
                  </v-row>
                  <template v-slot:actions>
                    <v-icon color="primary"> mdi-square-edit-outline </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col md="8" cols="12" class="py-0">
                      <v-text-field
                        v-model="task.title"
                        label="Entrez le titre de la tâche"
                        clearable
                        :rules="[(v) => !!v || 'Un titre est requis']"
                        required>
                      </v-text-field>
                    </v-col>
                    <v-col md="8" cols="12" class="py-0">
                      <v-textarea
                        v-model="task.description"
                        label="Entrez la description de la tâche"
                        auto-grow
                        rows="1"
                        clearable
                        :rules="[(v) => !!v || 'Une description est requise']"
                        required>
                      </v-textarea>
                    </v-col>
                    <v-col md="8" cols="12" class="py-0">
                      <v-select
                        v-model="task.competence_id"
                        :items="competenceStore.competences"
                        item-text="name"
                        item-value="id"
                        label="Select"
                        persistent-hint
                        single-line></v-select>
                    </v-col>
                    <v-col cols="12" class="pa-0"
                      ><v-subheader class="primary--text"
                        >Réponse</v-subheader
                      ></v-col
                    >
                    <v-col md="8" cols="12" class="py-0">
                      <v-select
                        class="py-0"
                        v-model="task.validation.validation_type"
                        :items="tasksStore.validationItems"
                        item-text="text"
                        item-value="id"
                        label="Select"
                        persistent-hint
                        single-line></v-select>
                    </v-col>
                    <v-col md="8" cols="12" class="py-0">
                      <v-text-field
                        v-model="task.validation.answer_text"
                        label="Entrez la réponse attendue"
                        clearable
                        :rules="[(v) => !!v || 'Un titre est requis']"
                        required>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row
                    class="align-items-center"
                    dense
                    v-if="!tasksStore.tasks == []">
                    <v-spacer></v-spacer>
                    <v-col class="text-right">
                      <v-btn
                        @click="removeTasks(tIndex, task)"
                        dark
                        small
                        color="red"
                        class="mr-2"
                        elevation="2">
                        <v-icon dark> mdi-delete </v-icon>
                        {{ 'Supprimer la tâche' }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-col>
          </draggable>
        </v-row>
      </v-expansion-panels>
    </div>
    <v-skeleton-loader
      v-else-if="tasksStore.isLoading.tasks"
      v-bind="attrs"
      v-for="i in 5"
      :key="i"
      type="card-heading"></v-skeleton-loader>
    <div v-if="!tasksStore.isLoading.tasks && tasksStore.tasks && tasksStore.tasks.length == 0">
      <v-alert color="grey" type="info">Aucune tâche pour l'instant...</v-alert>
    </div>
    <div class="sticky fixedbottom">
      <v-btn
        @click="openAddTasksDialog"
        dark
        medium
        fab
        color="primary"
        top
        right
        elevation="6">
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
      <v-btn
        v-if="tasksStore.tasks && tasksStore.tasks.length > 0"
        @click="saveTasks"
        dark
        medium
        color="accent"
        fab
        right
        elevation="6"
        class="ml-2">
        <v-icon dark> mdi-content-save </v-icon>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { useTasksStore } from '@/store/tasks';
import { useChallengeStore } from '@/store/challenge';
import { useCompetenceStore } from '@/store/competence';
import { mapStores } from 'pinia';
import draggable from 'vuedraggable';
export default {
  components: {
    draggable,
  },
  data: () => ({
    attrs: {
      class: 'mb-6',
      boilerplate: true,
      elevation: 2,
    },
  }),

  mounted() {
    this.competenceStore.getSelectableCompetences();
  },
  computed: {
    ...mapStores(useTasksStore),
    ...mapStores(useChallengeStore),
    ...mapStores(useCompetenceStore),
  },
  methods: {
    saveTasks() {
      this.setTasksOrder();
      this.tasksStore.updateTasks(
        this.challengeStore.currentChallengeData.id,
        this.tasksStore.tasks
      );
      this.$router.push({
        name: 'challenges',
      });
    },
    removeTasks(tIndex, task) {
      this.tasksStore.tasks.splice(tIndex, 1);
      this.tasksStore.deleteTask(
        this.challengeStore.currentChallengeData.id,
        task.id
      );
    },
    setTasksOrder(){
      this.tasksStore.tasks.forEach((task, index) => {
        task.order = index;
      });
    },
    openAddTasksDialog() {
      this.tasksStore.showAddTasksDialog = true;
    },
    backChallenges() {
      this.$router.push({
        name: 'challenges',
      });
    },
  },
};
</script>

<style scoped>
#toolbar-tasks {
  height: 6px !important;
  border-radius: 4px 4px 0px 0px !important;
}

#tasks-draggable {
  width: 100%;
}
</style>
