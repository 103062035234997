import ChallengeService from '../services/challenge.service';
import { defineStore } from 'pinia';
import _ from 'lodash';

export const useChallengeStore = defineStore('challenge', {
  state: () => ({
    isLoading: {
      challenges: true,
      currentChallenge: true,
    },
    inputDuration: null,
    challenges: [],
    currentChallengeData: {
      id: null,
      name: '',
      description: '',
      tasks_count: 0,
      isActive: false,
      duration: null,
      tasks: [],
      documents: [],
    },
    initialChallengeData: {
      id: null,
      name: '',
      description: '',
      tasks_count: 0,
      isActive: false,
      duration: null,
      tasks: [],
      documents: [],
    },
    tasks: [],
    currentTasksData: {},
    initialTasksData: {},
    score: 0,
    documents: null,
    showChallengeDialog: false,
    showDeleteChallengeDialog: false,
    showAddTasksDialog: false,
    showDetailsDialog: false,
  }),

  getters: {
    hasIntroStep() {
      return (this.currentChallengeData.documents.length > 0 || this.currentChallengeData.instruction);
    },
  },

  actions: {
    getActiveChallenges() {
      ChallengeService.getChallenges()
        .then((response) => {
          this.challenges = response.data;
          this.isLoading.challenges = false;
          this.showDetailsDialog = true;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getChallenge(id) {
      ChallengeService.getChallenge(id)
        .then((response) => {
          this.currentChallengeData = response.data;
          this.isLoading.currentChallenge = false;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getAllChallenges() {
      ChallengeService.getAllChallenges().then((response) => {
        this.challenges = response.data;
        this.isLoading.challenges = false;
      });
    },
    createChallenge(challenge) {
      return ChallengeService.createChallenge(challenge).then((response) => {
        this.challenges.push(response.data);
      });
    },
    getChallengeDocuments(id, docname) {
      return ChallengeService.getChallengeDocuments(id).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', docname);
        document.body.appendChild(link);
        link.click();
      });
    },
    updateChallenge(id, challenge) {
      return ChallengeService.updateChallenge(id, challenge);
    },
    deleteChallenge(id) {
      return ChallengeService.deleteChallenge(id);
    },
    startChallenge(id) {
      return ChallengeService.startChallenge(id);
    },
    completeChallenge(id) {
      return ChallengeService.completeChallenge(id).then((response) => {
        this.score = Math.round(response.data.score);
      });
    },
    startChallengeTasks(cid, tid) {
      return ChallengeService.startChallengeTasks(cid, tid);
    },
    answerChallengeTasks(cid, tid, answerDTO) {
      return ChallengeService.answerChallengeTasks(cid, tid, answerDTO);
    },
    initialiseCurrentChallengeData() {
      this.inputDuration = '00:00';
      this.currentChallengeData = _.clone(this.initialChallengeData);
    },
  },
});
