<template>
  <v-card class="challenge-card">
    <div :class="`background ${colorClass} pa-8 d-flex justify-center`">
      <v-img
        v-if="challenge.main_competence.image"
        :src="challenge.main_competence.image"
        max-height="85px"
        max-width="85px"></v-img>
    </div>
    <v-card-text>
      <div class="d-flex align-start">
        <p class="text-h5 text--primary trunctitle" style="height: 6rem">
          {{ challenge.name }}
        </p>
        <v-icon v-if="challenge.is_finished_by_user" color="success" class="ml-2" size="18">mdi-check-circle-outline</v-icon>
      </div>
      <div class="text--primary" style="min-height: 8em">
        {{ challenge.description }}
      </div>
      <div v-if="challenge.tags.length > 0">
        <v-chip
          v-for="tag in challenge.tags"
          :key="tag.id"
          variant="outlined"
          :color="colorClass"
          class="ml-1">
          {{ tag.name }}
        </v-chip>
      </div>
      <!-- hack brother ewww -->
      <div v-else style="height:32px;">
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn
        class="d-flex align-end flex-column ml-auto mr-2"
        :color="colorClass"
        :loading="challengeReady"
        @click="showChallengeDetails">
        {{ challenge.is_finished_by_user ? 'Réessayer' : 'Commencer' }}
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <v-card
        v-if="reveal"
        :class="`challenge-card d-flex flex-column space-between transition-fast-in-fast-out v-card--reveal`"
        style="height: 100%">
        <v-card-text class="pb-0 mb-0">
          <p class="text-h7 text--primary">{{ challenge.name }}</p>
          <p>
            Nombre de tâches:
            {{ challenge.tasks_count }}
          </p>
          <p>
            Durée estimée du challenge :
            {{
              this.moment
                .duration(challenge.duration, 'seconds')
                .format('mm:ss')
            }}
            min
          </p>
          <v-alert border="left" dense type="warning">
            Il n’est pas possible de stopper le challenge une fois celui-ci
            commencé. La tentative sera comptabilisée dans vos
            statistiques.</v-alert
          >
          <v-alert border="left" dense type="warning">
            Si vous passez une tâche, il ne sera plus possible de revenir en
            arrière.</v-alert
          >
          <!-- a btn to download files -->
          <!-- <div class="d-flex justify-content-between">
            <div
              v-for="document in challenge.documents"
              :key="document.id"
              class="col-2">
              <v-btn
                class="mt-2 ml-2 d-flex justify-center"
                fab
                small
                @click="downloadDocument(document.id, document.name)"
                color="secondary">
                <v-icon>mdi-file</v-icon>
              </v-btn>
              <div class="d-flex justify-center">
                {{
                  document.name.length <= 12
                    ? document.name
                    : document.name.slice(0, 12) + '...'
                }}
              </div>
            </div>
          </div> -->
        </v-card-text>
        <!-- end a btn to download files -->
        <v-card-actions class="mt-auto pt-0">
          <v-btn text color="secondary" @click="reveal = false"> Fermer </v-btn>
          <v-btn
            outlined
            class="d-flex align-end flex-column ml-auto mr-2"
            color="secondary"
            :loading="challengeReady"
            @click="startChallenge()">
            Démarrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { useChallengeStore } from '../store/challenge';
import { mapStores } from 'pinia';
import moment from 'moment';
import 'moment-duration-format';
export default {
  data: () => ({
    reveal: false,
    moment: moment,
    challengeReady: false,
    serverUrl: process.env.VUE_APP_SERVER_URL,
  }),
  props: {
    challenge: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.challengeStore.isLoading.currentChallenge = true;
  },
  computed: {
    ...mapStores(useChallengeStore),
    colorClass() {
      return this.challenge.main_competence?.color || 'blue-grey lighten-1';
    },
  },
  watch: {
    'challengeStore.isLoading.currentChallenge': function (newVal) {
      if (!newVal) {
        this.$router.push({ name: 'wizard' });
        this.challengeReady = false;
      }
    },
  },
  methods: {
    showChallengeDetails() {
      this.reveal = true;
    },
    startChallenge() {
      this.challengeReady = true;
      this.challengeStore.getChallenge(this.challenge.id);
    },
    // downloadDocument(id, name) {
    //   this.challengeStore.getChallengeDocuments(id, name);
    // },
  },
};
</script>

<style>
.trunctitle {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: normal;
}
.truncdesc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: normal;
}
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.challenge-card.v-sheet.v-card {
  border-radius: 20px !important;
  height: 100% !important;
}

.challenge-card .background {
  border-bottom-left-radius: 60%;
  border-bottom-right-radius: 60%;
  opacity: 0.8;
  min-height: 85px;
}

.challenge-card .background.yellow {
  background: repeating-linear-gradient(
    45deg,
    white,
    white 5px,
    transparent 5px,
    transparent 25px
  );
}

.challenge-card .background.orange {
  background: repeating-linear-gradient(
    -45deg,
    white,
    white 5px,
    transparent 5px,
    transparent 25px
  );
}

.challenge-card .background.primary {
  background: linear-gradient(white 2px, transparent 2px) 0 -1px,
    linear-gradient(90deg, white 2px, transparent 2px) -1px 0;
  background-size: 20px 20px, 20px 20px, 10px 10px, 10px 10px;
}

.challenge-card .background.green {
  background: linear-gradient(white 2px, transparent 2px) 0 -1px,
    linear-gradient(90deg, white 2px, transparent 2px) -1px 0;
  background-size: 50px 50px, 50px 50px, 25px 25px, 25px 25px;
}

.challenge-card .background.pink {
  background: linear-gradient(white 2px, transparent 2px) 0 -1px,
    linear-gradient(90deg, white 2px, transparent 2px) -1px 0;
  background-size: 100px 100px, 100px 100px, 50px 50px, 50px 50px;
}
</style>
