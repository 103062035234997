<template>
  <div>
    <v-alert border="left" color="primary" dense text type="info"
      >{{ message }}
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'AlertNoDataAvailable',
  props: {
    message: {
      type: String,
      default:
        "Pas de données disponibles. Vous n'avez pas encore réalisé de challenge.",
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped></style>
