import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import PreviousChallenges from '../views/PreviousChallengesView.vue';
import ChallengeView from '../views/ChallengeView.vue';
import Login from '../views/Login.vue';
import Register from '../views/Register.vue';
import TasksView from '../views/TasksView.vue';
import ChallengeWizard from '../views/ChallengeWizard.vue';
import ManagerStats from '../views/ManagerStats.vue';
import ProfileView from '../views/ProfileView.vue';
import { useAuthStore } from '../store/auth';
import { useTagsStore } from '../store/tags';

Vue.use(VueRouter);

const routes = [
  {
    path: '/previouschallenges',
    name: 'previouschallenges',
    component: PreviousChallenges,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
  },
  {
    path: '/admin',
    name: 'admin',
  },
  {
    path: '/challenges',
    name: 'challenges',
    component: ChallengeView,
  },
  {
    path: '/challenges/tasks',
    name: 'tasks',
    component: TasksView,
  },
  {
    path: '/wizard',
    name: 'wizard',
    component: ChallengeWizard,
  },
  {
    path: '/stats',
    name: 'ManageStats',
    component: ManagerStats,
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register'];
  const authRequired = !publicPages.includes(to.path);
  // const loggedIn = localStorage.getItem('user');
  // Use pinia store instead of local storage
  const  authStore = useAuthStore();
  const loggedIn = authStore.user;
  const accessCode = to.query.access_token;
  const tagsStore = useTagsStore();
  const routeTags = to.query.tags;
  if (accessCode) {
    // If access token is present, store it in the store
    authStore.setAccessCode(accessCode);
  }
   if (routeTags) {
      tagsStore.loading = true;
     const tagsArray = Array.isArray(routeTags) ? routeTags : [routeTags];
     tagsStore.selectedTags = tagsArray.map((tag) => Number(tag));
   }
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    return next('/login');
  }
  next();
});

router.beforeEach((to, from, next) => {
  if (to.path === '/admin') {
    window.location = process.env.VUE_APP_ADMIN_URL || 'http://localhost/admin';
  } else {
    next();
  }
});

export default router;
