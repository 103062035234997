import axios from 'axios';

class ChallengeService {
  getChallenges() {
    return axios({
      url: '/challenges',
      method: 'GET',
    });
  }
  getAllChallenges() {
    return axios({
      url: '/challenges?admin',
      method: 'GET',
    });
  }
  getChallenge(id) {
    return axios({
      url: '/challenges/' + id,
      method: 'GET',
    });
  }
  getChallengeDocuments(id) {
    return axios({
      url: '/documents/' + id + '/download',
      method: 'GET',
      responseType: 'blob',
    });
  }
  createChallenge(challenge) {
    return axios({
      url: '/challenges',
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      data: challenge,
    });
  }
  updateChallenge(id, challenge) {
    return axios({
      url: '/challenges/' + id,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: challenge,
    });
  }
  deleteChallenge(id) {
    return axios({
      url: '/challenges/' + id,
      method: 'DELETE',
    });
  }
  startChallenge(id) {
    return axios({
      url: '/challenges/' + id + '/start',
      method: 'POST',
    });
  }
  completeChallenge(id) {
    return axios({
      url: '/challenges/' + id + '/complete',
      method: 'POST',
    });
  }
  startChallengeTasks(cid, tid) {
    return axios({
      url: '/challenges/' + cid + '/tasks/' + tid + '/start',
      method: 'POST',
    });
  }
  answerChallengeTasks(cid, tid, answerDTO) {
    return axios({
      url: '/challenges/' + cid + '/tasks/' + tid + '/answer',
      method: 'POST',
      data: answerDTO,
    });
  }
}
export default new ChallengeService();
