<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="mini"
      :expand-on-hover="mini"
      permanent
      dark
      class="secondary"
      clipped
      app
      style='z-index:20001;'>
      <!-- COLLABORATOR MENU -->
      <v-list dense nav class="no-text-decoration">
        <v-list-item
          v-for="item in publicMenu"
          :key="item.title"
          :to="item.to"
          link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- END COLLABORATOR MENU -->
        <!-- MANAGER MENU -->
        <div id="menu-dashboard" v-if="isAdminOrManager">
          <v-divider></v-divider>
          <v-list-item
            v-for="item in managerMenu"
            :key="item.title"
            :to="item.link">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <!-- END MANAGER MENU -->
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { useAuthStore } from '../store/auth';
import { mapStores } from 'pinia';
export default {
  data: () => ({
    drawer: true,
    right: null,
    mini: true,
  }),
  computed: {
    ...mapStores(useAuthStore),
    isAdminOrManager() {
      return this.hasRole('admin') || this.hasRole('manager');
    },
    publicMenu() {
      return [
        {
          title: 'Mes Challenges',
          icon: 'mdi-view-dashboard',
          to: '/',
        },
        {
          title: 'Mes Résultats',
          icon: 'mdi-thermostat',
          to: '/previouschallenges',
        },
      ];
    },
    managerMenu() {
      return [
        {
          title: 'Statistiques',
          icon: 'mdi-thermostat-box',
          link: '/stats',
        },
        {
          title: 'Gestion Challenges',
          icon: 'mdi-book',
          link: '/challenges',
        },
        {
          title: 'Back-Office',
          icon: 'mdi-cog',
          link: '/admin',
        },
      ];
    },
  },
  props: ['message'],
};
</script>
<style scoped>
.v-navigation-drawer__content .v-list-item {
  text-decoration: none;
}
</style>
