<template>
  <div>
    <task-edition />
    <v-dialog
      v-model="tasksStore.showAddTasksDialog"
      transition="dialog-right-transition"
      max-width="900">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click.stop="closeAddTasksDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <!-- Edit Tasks title -->
        <v-toolbar-title v-if="tasksStore.tasks && tasksStore.tasks.length > 0">
          {{ 'Edition des tâches' }}
        </v-toolbar-title>
        <!-- Create Tasks title -->
        <v-toolbar-title v-else>
          {{ 'Création de tâches' }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <tasks-add-dialog></tasks-add-dialog>
    </v-dialog>
  </div>
</template>

<script>
import TaskEdition from '../components/TasksEdition.vue';
import TasksAddDialog from '../components/TasksAddDialog.vue';
import { mapStores } from 'pinia';
import { useTasksStore } from '@/store/tasks';
export default {
  components: {
    TaskEdition,
    TasksAddDialog,
  },
  data: () => ({}),

  mounted() {},
  computed: {
    ...mapStores(useTasksStore),
  },
  methods: {
    closeAddTasksDialog() {
      this.tasksStore.showAddTasksDialog = false;
      this.tasksStore.initialiseCurrentTasksData();
    },
  },
};
</script>

<style>
#toolbar-tasks {
  height: 6px !important;
  border-radius: 4px 4px 0px 0px !important;
}
</style>
