import axios from 'axios';

class CompetenceService {
  async getCompetences() {
    const res = await axios({
      url: '/competences',
      method: 'GET',
    });
    return res;
  }

  async getSelectableCompetences() {
    const res = await axios({
      url: '/competences?selectable=true',
      method: 'GET',
    });
    return res;
  }

  async getCompetencesCategories() {
    const res = await axios({
      url: '/competences?selectable=false',
      method: 'GET',
    });
    return res;
  }

  async getCompetence(id) {
    const res = await axios({
      url: '/competences/' + id,
      method: 'GET',
    });
    return res;
  }
}
export default new CompetenceService();
