<template>
  <v-card class="h-100">
    <v-card-text class="text-subtitle-1">
      <v-icon class="mr-2">mdi-chart-pie-outline</v-icon>
      La répartition des compétences sur la plateforme
    </v-card-text>
    <div class="d-flex justify-center py-4">
      <div class="d-flex text-center align-items-center">
        <v-overlay :value="loading" absolute opacity="0.1">
          <v-progress-circular
            indeterminate
            color="white"></v-progress-circular>
        </v-overlay>
        <ChartComponent
          :style="chartContainerStyle"
          :chartData="chartData"
          canvasId="PieChart1"
          v-if="!loading && cubeData.length > 0"></ChartComponent>
        <div v-if="cubeData.length == 0 && !loading">
          <AlertNoDataAvailable></AlertNoDataAvailable>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapStores } from 'pinia';
import { useCompetenceStore } from '@/store/competence';
import { useStatsStore } from '@/store/stats';
import axios from 'axios';
import ChartComponent from './ChartComponent.vue';
import AlertNoDataAvailable from '@/components/AlertNoDataAvailable.vue';

export default {
  name: 'RatioCompetenceChart.vue',
  components: { ChartComponent, AlertNoDataAvailable },
  data() {
    return {
      cubeData: [],
      loading: true,
    };
  },
  async mounted() {
    await this.competenceStore.getCompetencesCategories();
    this.fetchData();
  },
  watch: {
    'statsStore.currentStatsUserId': 'fetchData',
  },
  methods: {
    fetchData() {
      axios
        .get('/stats/users/' + this.statsStore.currentStatsUserId + '/count', {
          params: {
            cube: 'Tasks',
            dimensions: [
              'CompetencesCategories.id',
              'CompetencesCategories.name',
            ],
            limit: 100,
          },
        })
        .then((response) => {
          if (response.data.data) {
            this.cubeData = response.data.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCategoryColor(id) {
      const category = this.competenceStore.competencesCategories.find(
        (c) => c['id'] == id
      );
      return category
        ? category.color == 'primary'
          ? 'lightBlue'
          : category.color
        : `rgb(${Math.random() * 255}, ${Math.random() * 255}, ${
            Math.random() * 255
          })`;
    },
    getCategoryName(id) {
      const category = this.competenceStore.competencesCategories.find((c) => {
        return c['id'] == id;
      });
      return category ? category.name : id;
    },
  },
  computed: {
    ...mapStores(useCompetenceStore),
    ...mapStores(useStatsStore),

    chartContainerStyle() {
      return {
        minWidth: this.$vuetify.breakpoint.xs ? '300px' : '700px',
      };
    },

    chartDatasets() {
      let data = this.cubeData;

      // Retrieve the data from CubeData and format it for Chart.js
      let datasets = [];
      let labels = [];
      let backgroundColor = [];
      let borderColor = [];
      let dataValues = [];
      data.forEach((element) => {
        labels.push(
          this.getCategoryName(element['CompetencesCategories.name'])
        );
        backgroundColor.push(
          this.getCategoryColor([element['CompetencesCategories.id']])
        );
        borderColor.push(
          this.getCategoryColor(element['CompetencesCategories.id'])
        );
        dataValues.push(element['Tasks.count']);
      });
      datasets.push({
        label: 'Nombre de tâches existantes',
        data: dataValues,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
      });
      return datasets;
    },
    chartLabels() {
      let labels = [];
      this.cubeData.forEach((element) => {
        labels.push(
          this.getCategoryName(element['CompetencesCategories.name'])
        );
      });
      return labels;
    },

    chartData() {
      return {
        type: 'doughnut',
        data: {
          labels: this.chartLabels,
          datasets: this.chartDatasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'top',
            },
          },
        },
      };
    },
    cubeCount: function () {
      return this.cubeData[this.dataKey]
        ? Math.floor(this.cubeData[this.dataKey]) || 0
        : 0;
    },
  },
};
</script>

<style></style>
