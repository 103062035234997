<template>
  <v-card class="mx-auto">
    <v-toolbar color="secondary" dark>
      <v-icon class="mr-2">mdi-book</v-icon>
      <v-toolbar-title>Nombre de challenge</v-toolbar-title>
    </v-toolbar>
    <v-overlay :value="loading" absolute opacity="0.1">
      <v-progress-circular indeterminate color="white"></v-progress-circular>
    </v-overlay>
    <v-card-text v-if="!loading" class="text-h1 text-center">
      {{ Challenges }}
    </v-card-text>
  </v-card>
</template>

<script>
import { mapStores } from 'pinia';
import { useAuthStore } from '@/store/auth';
import axios from 'axios';

export default {
  data() {
    return {
      cubeData: [],
      Challenges: 0,
      loading: true,
    };
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      axios
        .get('/stats/users/' + this.authStore.user.id + '/count', {
          params: {
            cube: 'Challenges',
            limit: 100,
          },
        })
        .then((response) => {
          this.cubeData = response.data.data;
          this.Challenges = this.cubeData[0]['Challenges.count'];
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  computed: {
    ...mapStores(useAuthStore),
  },
};
</script>

<style></style>
