<template>
  <div>
    <v-card flat title="Utilisateur-ices">
      <v-toolbar color="secondary" dark>
        <v-icon class="mr-2">mdi-thermostat-box</v-icon>
        <v-toolbar-title>Statistiques générales</v-toolbar-title>
      </v-toolbar>
      <template v-slot:text>
        <v-text-field
          v-model="search"
          label="Search"
          prepend-inner-icon="mdi-magnify"
          single-line
          variant="outlined"
          hide-details></v-text-field>
      </template>

      <v-data-table
        :headers="headers"
        :items="userStats"
        :loading="loading"
        :items-per-page="5"
        class="elevation-1"
        :search="search"
        @click:row="handleUserClick">
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Recherche"
            class="mx-4"
            append-icon="mdi-magnify"
            single-line
            hide-details></v-text-field>
        </template>
      </v-data-table>
    </v-card>
    <ManagerUserDetailsModal
      :dialogProp="dialog"
      :userDetails="currentUser"
      @update:dialogProp="dialog = $event"></ManagerUserDetailsModal>
  </div>
</template>

<script>
import { useAuthStore } from '@/store/auth';
import { useStatsStore } from '@/store/stats';
import { mapStores } from 'pinia';
import cubeService from '@/services/cube.service';
import ManagerUserDetailsModal from '@/components/Stats/ManagerUserDetailsModal.vue';

export default {
  name: 'ManagerListUsers',
  components: { ManagerUserDetailsModal },
  data() {
    return {
      search: '',
      loading: true,
      headers: [
        {
          align: 'start',
          value: 'Users.email',
          sortable: true,
          text: 'email',
        },
        { value: 'Users.firstName', text: 'Prénom' },
        { value: 'Users.lastName', text: 'Nom' },
        { value: 'ChallengeTrials.count', text: "Nombre d'essais" },
        { value: 'ChallengeTrials.avgScore', text: 'Score moyen (%)' },
      ],
      userStats: [],
      currentUser: {},
      dialog: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      cubeService.getUsersGlobalStats().then((response) => {
        this.userStats = response;
        this.loading = false;
      });
    },
    handleUserClick(item) {
      this.currentUser = item;
      this.statsStore.setCurrentStatsUserId(item['Users.id']);
      this.dialog = true;
    },
  },
  computed: {
    ...mapStores(useAuthStore),
    ...mapStores(useStatsStore),
  },
};
</script>

<style></style>
