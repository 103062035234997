<template>
  <div>
    <canvas :id="canvasId"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  name: 'ChartComponent.vue',
  props : ['chartData', 'canvasId'],
  data(){
    return {
        chart: null,
    }
  },
  mounted() {
    const ctx = document.getElementById(this.canvasId);
    this.chart = new Chart(ctx, this.chartData);
  },
};
</script>
