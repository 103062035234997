<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Register form</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <form ref="form" @submit.prevent="handleRegister">
                  <v-text-field
                    v-model="user.firstName"
                    name="firstName"
                    label="First name"
                    type="name"
                    placeholder="First name"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="user.lastName"
                    name="lastName"
                    label="Last name"
                    type="name"
                    placeholder="Last name"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="user.email"
                    name="email"
                    label="Email"
                    type="email"
                    placeholder="email"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="user.password"
                    name="password"
                    label="Password"
                    type="password"
                    placeholder="password"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="user.password_confirmation"
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    placeholder="confirm password"
                    required
                  ></v-text-field>
                  <div class="red--text">{{ errorMessage }}</div>
                  <v-btn
                    type="submit"
                    class="mt-4"
                    color="primary"
                    value="register"
                    >Register</v-btn
                  >
                  <div
                    class="grey--text mt-4"
                    v-on:click="$router.push('/login')"
                  >
                    <div class="grey--text mt-4">
                      Already have an account ? <strong>Log in</strong>
                    </div>
                  </div>
                </form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import User from '../models/user';
import { useAuthStore } from '../store/auth';
import { mapStores } from 'pinia';
export default {
  name: 'RegisterView',
  data() {
    return {
      user: new User('', '', '', ''),
      submitted: false,
      successful: false,
      errorMessage: '',
    };
  },
  computed: {
    ...mapStores(useAuthStore),
  },
  methods: {
    handleRegister() {
      this.errorMessage = '';
      this.submitted = true;
      console.log(this.$validator);
      this.authStore.register(this.user).then(
        () => {
          this.submitted = false;
          this.successful = true;
          this.$router.push('/login');
        },
        (error) => {
          this.submitted = false;
          this.errorMessage = error.response.data.message;
        }
      );
    },
  },
};
</script>
