<template>
  <div class="d-flex flex-wrap">
    <div
      v-for="categorie in competenceStore.competencesCategories"
      :key="categorie.id"
      class="mr-4 mt-2">
      <v-badge :color="categorie.color" class="mr-6"></v-badge>{{ categorie.name }}
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useCompetenceStore } from '@/store/competence';

export default {
  name: 'CategoriesLegends',
  mounted() {
    this.competenceStore.getCompetencesCategories();
  },
  methods: {},
  computed: {
    ...mapStores(useCompetenceStore),
  },
};
</script>

<style lang="scss" scoped>
.legend-square {
  width: 12px;
  height: 12px;
  display: inline-block;
}
</style>
