import { defineStore } from 'pinia';
import _ from 'lodash';
import TaskService from '@/services/task.service';

export const useTasksStore = defineStore('tasks', {
  state: () => ({
    isLoading: {
      tasks: true,
    },
    tasks: [],
    currentTasksData: {
      title: '',
      description: '',
      order: 0,
      competence_id: 1,
      validation : {
            validation_type : 'string_match',
            answer_text : ''
      }
    },
    initialTasksData: {
      title: '',
      description: '',
      order: 0,
      competence_id: 1,
      validation : {
        validation_type : 'string_match',
        answer_text : ''
      }
    },
    showAddTasksDialog: false,
    validationItems : [
      {
        id : 'string_match',
        text : 'Comparaison de text'
      }

    ],
  }),

  getters: {},

  actions: {
    getTasksByChallengeId(challengeId) {
      this.isLoading.tasks = true;
      TaskService.getTasksByChallengeId(challengeId).then((response) => {
        this.tasks = response.data;
        this.isLoading.tasks = false;
      });
    },
    deleteTask(challengeId, taskId) {
      TaskService.deleteTask(challengeId, taskId);
    },
    updateTasks(challengeId, tasks) {
      TaskService.updateTasks(challengeId, tasks);
    },
    createTask(challengeId, task) {
      TaskService.createTask(challengeId, task).then((response) => {
        this.tasks.push(response.data);
      });
    },
    initialiseCurrentTasksData() {
      this.currentTasksData = _.clone(this.initialTasksData);
    },
  },
});
