<template>
  <div>
    <ChartComponent
      :chartData="chartData"
      canvasId="multiLinesChart1"
      v-if="!loading"></ChartComponent>
    <div class="d-flex justify-content-center mt-6" v-else>
      <v-overlay :value="loading" absolute opacity="0.1">
        <v-progress-circular indeterminate color="white"></v-progress-circular>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapStores } from 'pinia';
import { useCompetenceStore } from '@/store/competence';
import { useStatsStore } from '@/store/stats';
import cubeService from '@/services/cube.service';
import ChartComponent from './ChartComponent.vue';

export default {
  name: 'MultiLinesChart.vue',
  components: { ChartComponent },
  data() {
    return {
      cubeData: [],
      loading: true,
    };
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    'statsStore.currentStatsUserId': 'fetchData',
  },
  computed: {
    ...mapStores(useCompetenceStore),
    ...mapStores(useStatsStore),

    chartDatasets() {
      let data = this.cubeData;
      // Group the data by CompetencesCategories.id and sort by TaskTrials.createdAt.month
      const groupedData = data.reduce((acc, item) => {
        const categoryId = item['CompetencesCategories.id'];
        const month = item['TaskTrials.createdAt.month'];
        const score = item['TaskTrials.avgScore'];

        if (!acc[categoryId]) {
          acc[categoryId] = [];
        }

        acc[categoryId].push({ month, score });
        return acc;
      }, {});

      const months = this.chartMonths;

      // Create a chart.js dataset for each CompetencesCategories.id
      const datasets = Object.entries(groupedData).map(
        ([categoryId, scores]) => {
          const scoresByMonth = months.map((month) => {
            const score = scores.find(
              (s) => moment(s.month).format('MM') === month.format('MM')
            );
            return score ? score.score : null;
          });

          return {
            label: `Category ${this.getCategoryName(categoryId)}`,
            data: scoresByMonth,
            fill: false,
            borderColor: this.getCategoryColor(categoryId),
          };
        }
      );
      return datasets;
    },
    chartMonths() {
      return Array.from({ length: moment().month() + 1 }, (x, i) =>
        moment().startOf('year').add(i, 'months')
      );
    },
    chartLabels() {
      return this.chartMonths.map((segment) =>
        segment.locale('fr').format('MMMM')
      );
    },
    chartData() {
      return {
        type: 'line',
        data: {
          labels: this.chartLabels,
          datasets: this.chartDatasets,
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: 'Month',
              },
            },
            y: {
              title: {
                display: true,
                text: 'Average Score',
              },
            },
          },
        },
      };
    },
  },

  methods: {
    fetchData() {
      this.competenceStore.getCompetencesCategories();
      cubeService
        .getAvgByCategoryForUser(this.statsStore.currentStatsUserId, {
          cube: 'TaskTrials',
          dimensions: ['CompetencesCategories.id'],
          limit: 100,
          granularity: 'month',
          dateRange: 'This year',
          avgName: 'avgScore',
        })
        .then((response) => {
          this.cubeData = response;
          this.loading = false;
        });
    },
    getCategoryColor(id) {
      const category = this.competenceStore.competencesCategories.find(
        (c) => c['id'] == id
      );
      return category
        ? category.color == 'primary'
          ? 'lightBlue'
          : category.color
        : `rgb(${Math.random() * 255}, ${Math.random() * 255}, ${
            Math.random() * 255
          })`;
    },
    getCategoryName(id) {
      const category = this.competenceStore.competencesCategories.find((c) => {
        return c['id'] == id;
      });
      return category ? category.name : id;
    },
  },
};
</script>

<style scoped></style>
