<template>
  <v-container>
    <h1 class="v-heading text-sm-h3 mb-4" v-if="!this.isAdminView">
      Ma progression
    </h1>
    <v-row>
      <v-col cols="12">
        <h2>Taux de réussite par compétence</h2>
      </v-col>
      <v-col cols="12">
        <UserCompetenceFireCharts></UserCompetenceFireCharts>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h2>Résultats par challenge</h2>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="cubeData"
          :items-per-page="10"
          class="elevation-1">
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h2>Autres stats</h2>
      </v-col>
      <v-col class="col-md-4 col-12">
        <user-avg-challenge-score></user-avg-challenge-score>
        <user-avg-duration class="mt-md-4"></user-avg-duration>
      </v-col>
      <v-col class="col-md-8 col-12">
        <ratio-competence-chart></ratio-competence-chart>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h3 class="mt-4">Cette année</h3>
        <div class="mb-4 subtitle">
          Score moyen par catégorie et par mois
        </div></v-col
      >
      <v-col>
        <MultiLinesChart></MultiLinesChart>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import { useAuthStore } from '../store/auth';
import { useStatsStore } from '../store/stats';
import { mapStores } from 'pinia';
import MultiLinesChart from '@/components/Stats/MultiLinesChart.vue';
import UserAvgChallengeScore from '@/components/UserAvgChallengeScore.vue';
import RatioCompetenceChart from '@/components/Stats/RatioCompetenceChart.vue';
import UserAvgDuration from '../components/Stats/UserAvgDuration.vue';
import UserCompetenceFireCharts from '../components/Stats/UserCompetenceFireCharts.vue';

export default {
  name: 'PreviousChallengesView',
  props: {
    isAdminViewProp: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      cubeData: [],
      cubeDataT1: [],
      headers: [
        { text: 'Challenges', value: 'Challenges.name' },
        { text: "Nombre d'essais", value: 'ChallengeTrials.count' },
        { text: 'Votre moyenne (%)', value: 'ChallengeTrials.avgScore' },
        { text: 'Moyenne générale (%)', value: 'ChallengeTrials.avgScoreAll' },
        { text: 'Durée moyenne (min:sec)', value: 'DurationByUser' },
        {
          text: 'Durée moyenne générale (min:sec)',
          value: 'DurationByAll',
        },
      ],
    };
  },
  mounted() {
    this.setUp();
    this.fetchData();
  },
  watch: {
    'statsStore.currentStatsUserId': 'fetchData',
  },
  methods: {
    setUp() {
      if (!this.isAdminView) {
        this.statsStore.setCurrentStatsUserId(this.authStore.user.id);
      }
    },
    fetchData() {
      const userId = this.statsStore.currentStatsUserId;
      const cube = 'ChallengeTrials';
      const dimensions = ['Challenges.name'];
      const limit = 100;
      const filter = 'user';

      axios
        .all([
          axios.get(`/stats/users/${userId}/count`, {
            params: { cube, dimensions, limit, filter },
          }),
          axios.get(`/stats/users/${userId}/avg`, {
            params: { cube, dimensions, avgName: 'avgScoreAll', limit },
          }),
          axios.get(`/stats/users/${userId}/avg`, {
            params: { cube, dimensions, avgName: 'avgScore', limit, filter },
          }),
          axios.get(`/stats/users/${userId}/avg`, {
            params: { cube, dimensions, avgName: 'duration', limit, filter },
          }),
          axios.get(`/stats/users/${userId}/avg`, {
            params: { cube, dimensions, avgName: 'durationAll', limit },
          }),
        ])
        .then(
          axios.spread((res1, res2, res3, res4, res5) => {
            const cubeData = res1.data.data;
            this.cubeDataT1 = res1.data.data;
            const cubeDataP1 = res2.data.data;
            const cubeDataP2 = res3.data.data;
            const cubeDataP3 = res4.data.data;
            const cubeDataP4 = res5.data.data;

            const newData = cubeData.map((item, index) => {
              const durationByUserVar =
                cubeDataP3[index]['ChallengeTrials.duration'];
              const durationAllVar =
                cubeDataP4[index]['ChallengeTrials.durationAll'];

              const durationByUserInSec = durationByUserVar
                ? Math.floor(durationByUserVar)
                : 0;
              const durationByAllInSec = durationAllVar
                ? Math.floor(durationAllVar)
                : 0;
              const durationByUser = `${Math.floor(durationByUserInSec / 60)}:${
                durationByUserInSec % 60
              }`;
              const durationByAll = `${Math.floor(durationByAllInSec / 60)}:${
                durationByAllInSec % 60
              }`;

              return {
                'Challenges.name': item['Challenges.name'],
                'ChallengeTrials.count': Math.floor(
                  item['ChallengeTrials.count']
                ),
                'ChallengeTrials.avgScore': Math.floor(
                  cubeDataP2[index]['ChallengeTrials.avgScore']
                ),
                'ChallengeTrials.avgScoreAll': Math.floor(
                  cubeDataP1[index]['ChallengeTrials.avgScoreAll']
                ),
                'ChallengeTrials.duration': durationByUserInSec,
                'ChallengeTrials.durationAll': durationByAllInSec,
                DurationByUser: durationByUser,
                DurationByAll: durationByAll,
              };
            });

            this.cubeData = newData;
            this.loading = false;
          })
        )
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    ...mapStores(useAuthStore),
    ...mapStores(useStatsStore),
    isAdminView() {
      return this.authStore.isManagerOrAdmin && this.isAdminViewProp;
    },
  },
  components: {
    MultiLinesChart,
    UserAvgChallengeScore,
    RatioCompetenceChart,
    UserAvgDuration,
    UserCompetenceFireCharts,
  },
};
</script>
<style>
</style>
