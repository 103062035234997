<template>
  <v-container>
    <h1 class="v-heading text-h3 text-sm-h3 mb-4">Progression générale</h1>
    <v-row>
      <v-col>
        <manager-user-count></manager-user-count>
      </v-col>
      <v-col>
        <manager-challenge-count></manager-challenge-count>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <manager-list-users></manager-list-users>
      </v-col>  
    </v-row>
  </v-container>
</template>

<script>
import ManagerUserCount from '../components/Stats/ManagerUserCount.vue';
import ManagerChallengeCount from '../components/Stats/ManagerChallengeCount.vue';
import ManagerListUsers from '../components/Stats/ManagerListUsers.vue';

export default {
  name: 'ManagerStats.vue',
  components: { ManagerUserCount, ManagerChallengeCount, ManagerListUsers },
};
</script>
<style>
</style>
