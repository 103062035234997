//pinia store for tags
import { defineStore } from 'pinia';
import axios from 'axios';

export const useTagsStore = defineStore('tags', {
  state: () => {
    return {
      tags: [],
      selectedTags: [],
      loading: false,
    };
  },
  actions: {
    getTags() {
      axios({
        url: '/tags',
        method: 'GET',
      }).then((response) => {
        this.tags = response.data;
      });
    },
  },
});
